import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../components/interactive/button"
import Heading from "../components/content/heading"
import Section from "../components/containers/section"
import SectionContent from "../components/containers/section-content"
import Rows from "../components/grid/rows"
import Columns from "../components/grid/columns"
import { Content } from "../components/grid"
import CtaGroup from "../components/containers/cta-group"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import SectionBackground from "../components/containers/section-background"
import Card from "../components/containers/card"
import Dropdown from "../components-smart/interactive/dropdown"
import PardotForm from "../components-smart/forms/pardot-form"
import faq from "../utils/mlpcontent"
import Link from "../components/content/link"

function MultiplatformWebinar({ location }) {
  const seoObj = {
    title: "Free Webinar: How to Stop Click Fraud on EVERY Platform",
    description:
      "Learn how to stop click fraud on every ad platform and acquisition channel with Lunio’s latest product offering.",
    url: location && location.pathname,
    image: "/mlp-cover-photo.png",
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <Section>
        <div className="section__bg mlp__sky">
          <StaticImage
            src="../images/multiplatform-lp/cloud-one.png"
            alt="cloud"
            className="mlp__cloud mlp__cloud--one"
            style={{ position: "absolute" }}
          />
          <StaticImage
            src="../images/multiplatform-lp/cloud-two.png"
            alt="cloud"
            className="mlp__cloud mlp__cloud--two"
          />
          <StaticImage
            src="../images/multiplatform-lp/ship.png"
            alt="ship"
            className="mlp__ship"
          />
          <StaticImage
            src="../images/multiplatform-lp/cloud-three.png"
            alt="cloud"
            className="mlp__cloud mlp__cloud--three"
          />
          <div className="mlp__bg">
            <StaticImage
              layout="fullWidth"
              src="../images/multiplatform-lp/top.png"
              alt="Multiplatform bg image"
            />
          </div>
          <div className="mlp__accent mlp__accent--1">
            <StaticImage
              src="../images/multiplatform-lp/coin-one.png"
              alt="coin"
            />
          </div>
        </div>
        <SectionContent paddingTop="xs" paddingBot="sm">
          <Rows gap="none">
            <Columns count="1">
              <Content>
                <div style={{ width: "207px", marginBottom: "4rem" }}>
                  <StaticImage src="../images/logo/logo-black.svg" />
                </div>
              </Content>
            </Columns>
            <Columns count="2">
              <Content className="tab-mb-xxl">
                <Heading level={3}>
                  Live Webinar: The Future of Click Fraud Prevention
                </Heading>
                <p>
                  Join Lunio for the first look at the future of click fraud
                  prevention - preventing click fraud proactively across EVERY
                  ad network and acquisition channel!
                </p>
                <Heading level={4}>Hosted By:</Heading>
                <div id="form" className="mlp__speakers">
                  <div className="mlp__speakers__host">
                    <StaticImage
                      src="../images/multiplatform-lp/pete.png"
                      alt="Pete rawlinson lunio"
                    />
                    <div className="mlp__speakers__details">
                      <p>Pete Rawlinson</p>
                      <p>CMO</p>
                    </div>
                  </div>
                  <div className="mlp__speakers__host">
                    <StaticImage
                      src="../images/multiplatform-lp/amar.png"
                      alt="amar chana lunio"
                    />
                    <div className="mlp__speakers__details">
                      <p>Amar Chana</p>
                      <p>Partnerships Lead</p>
                    </div>
                  </div>
                </div>
                <PardotForm
                  url="https://info.ppcprotect.com/l/931773/2021-11-08/5d7t"
                  height={500}
                />
              </Content>
              <Columns count="2,1" className="mlp__boxes">
                <Content className="mlp__cards">
                  <Card styleType="solid" padding="md">
                    <Heading level={4}>Webinar Date</Heading>
                    <Rows gap="xxxs">
                      <p className="text--small">Tuesday 30th November</p>
                      <p className="text--small">4pm GMT</p>
                    </Rows>
                  </Card>
                </Content>
              </Columns>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
      <Section className="mlp__section--mid">
        <SectionBackground>
          <div className="mlp__seperator mlp__seperator--mid">
            <StaticImage
              src="../images/multiplatform-lp/mid.png"
              alt="Multiplatform bg image"
              layout="fullWidth"
            />
          </div>
          <div className="mlp__accent mlp__accent--2">
            <StaticImage
              src="../images/multiplatform-lp/coin-two.png"
              alt="coin"
            />
          </div>
          <div className="mlp__accent mlp__accent--3">
            <StaticImage
              src="../images/multiplatform-lp/gem-one.png"
              alt="gem"
            />
          </div>
          <div className="mlp__accent mlp__accent--4">
            <StaticImage
              src="../images/multiplatform-lp/gem-two.png"
              alt="gem"
            />
          </div>
        </SectionBackground>
        <SectionContent paddingTop="xs" paddingBot="none">
          <Rows>
            <Columns count="1">
              <Content className="mlp__faq">
                <Heading className="mb-lg" preheading="Facts And Questions!">
                  What's This Webinar About?
                </Heading>
                <Dropdown defaultSelected={1} content={faq} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
      <Section className="mlp__section--bot">
        <SectionBackground>
          <StaticImage
            src="../images/multiplatform-lp/bot.png"
            alt="Multiplatform bg image"
            className="mlp__seperator"
          />
          <div className="mlp__accent mlp__accent--5">
            <StaticImage
              src="../images/multiplatform-lp/gem-three.png"
              alt="gem"
            />
          </div>
          <div className="mlp__accent mlp__accent--6">
            <StaticImage
              src="../images/multiplatform-lp/gem-four.png"
              alt="gem"
            />
          </div>
        </SectionBackground>
        <SectionContent paddingTop="xs">
          <Rows>
            <Columns
              count="1"
              alignment="center"
              className="mb-xs logo-row__columns"
            >
              <Content paddingLeft="md" className="logo-row__column">
                <div className="logo-row__image--active">
                  <StaticImage
                    src="../images/multiplatform-lp/diesel-white.png"
                    alt="logo"
                  />
                </div>
              </Content>
              <Content paddingLeft="md" className="logo-row__column">
                <div className="logo-row__image--active">
                  <StaticImage
                    src="../images/multiplatform-lp/europcar-white.png"
                    alt="logo"
                  />
                </div>
              </Content>
              <Content paddingLeft="md" className="logo-row__column">
                <div className="logo-row__image--active">
                  <StaticImage
                    src="../images/multiplatform-lp/nike-white.png"
                    alt="logo"
                  />
                </div>
              </Content>
              <Content paddingLeft="md" className="logo-row__column">
                <div className="logo-row__image--active">
                  <StaticImage
                    src="../images/multiplatform-lp/shake-shack-white.png"
                    alt="logo"
                  />
                </div>
              </Content>
              <Content paddingLeft="md" className="logo-row__column">
                <div className="logo-row__image--active">
                  <StaticImage
                    src="../images/multiplatform-lp/hugo-boss-white.png"
                    alt="logo"
                  />
                </div>
              </Content>
            </Columns>
            <Columns count="1">
              <CtaGroup alignment="center">
                <Button color="light" href="#form">
                  Secure your Seat
                </Button>
              </CtaGroup>
            </Columns>
            <Columns className="mt-lg" count="1" alignment="center">
              <p className="text--color-white text--strong mlp__copyright">
                <Link to="/privacy-policy/" className="link--light">
                  Privacy
                </Link>
                <span className="mlp__copyright--seperator">|</span>
                <span>© Lunio 2022</span>
                <span className="mlp__copyright--seperator">|</span>
                <Link to="/terms-of-service/" className="link--light">
                  Terms of Use
                </Link>
              </p>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export default MultiplatformWebinar
