import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function DropdownItem({
  active, type, onClick, icon, title, children,
}) {
  const classes = classNames(
    'dropdown__item',
    type === 'solid' && 'dropdown__item--solid',
    !icon && 'dropdown__item--noicon',
    active && 'dropdown__item--active',
  );

  const handleClick = () => {
    if (active) return;
    onClick();
  };

  return (
    <div
      role="button"
      tabIndex="0"
      onKeyDown={handleClick}
      className={classes}
      onClick={handleClick}
    >
      <div className="dropdown__card">
        {icon && <FontAwesomeIcon className="dropdown__icon" icon={icon} />}
        <p className="h4 h4--sm dropdown__title">{title}</p>
        <FontAwesomeIcon className="dropdown__arrow" icon={faArrowDown} />
        <div className="dropdown__content">{children}</div>
      </div>
    </div>
  );
}

DropdownItem.defaultProps = {
  icon: false,
};

DropdownItem.propTypes = {
  active: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['solid', 'blank']),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DropdownItem;
