import React, { useState } from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import { Rows } from "../../components/grid"
import DropdownItem from "../../components/interactive/dropdown-item"

function Dropdown({ content, type, defaultSelected }) {
  const [selected, setSelected] = useState(defaultSelected - 1)
  return (
    <Rows gap="none" className="dropdown">
      <div className="card--career-listing__border" />
      {content.map(({ title, icon, copy }, index) => {
        const clickFnc = () => {
          setSelected(index)
        }

        return (
          <DropdownItem
            key={index}
            onClick={clickFnc}
            active={selected === index}
            icon={icon}
            title={title}
            type={type}
          >
            {parse(copy)}
          </DropdownItem>
        )
      })}
    </Rows>
  )
}

Dropdown.defaultProps = {
  type: "blank",
  defaultSelected: false,
}

Dropdown.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    }),
  ),
  type: PropTypes.oneOf(["solid", "blank"]),
  defaultSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

export default Dropdown
