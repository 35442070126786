import React, { useRef, useState, useEffect } from 'react';
import HTMLReactParser from 'html-react-parser';

function PardotForm({ url, height }) {
  const containerDiv = useRef(null);
  const [formLoaded, setFormLoaded] = useState(true);

  useEffect(() => {
    if (containerDiv.current.offsetHeight <= 100) {
      setFormLoaded(false);
    }
  }, []);

  return (
    <div>
      <div ref={containerDiv}>
        {HTMLReactParser(
          `<iframe src=${url} width="100%" height=${height} type="text/html" frameborder="0" style="border: 0"></iframe>`,
        )}
      </div>
      {!formLoaded && (
        <p className="mt-sm text--center text--color-red">
          Please Disable AdBlock and Refresh to enable our Form
          {' '}
          <br />
          <span className="text--xs text--color-grey">
            If you don't have AdBlock enabled and see this please contact us.
          </span>
        </p>
      )}
    </div>
  );
}

PardotForm.defaultProps = {
  url: null,
  height: 360,
};

export default PardotForm;
