const faq = [
  {
    title: "What will I learn?",
    copy: "<p>You'll learn about the next generation click fraud prevention platform from Lunio. You will see how you can prevent click fraud across all your acquisition channels, using excluded data from one to inform actions on another.</p>",
  },
  {
    title: "Is this just a sales webinar?",
    copy: "<p>Definitely not! We'll be diving into how our groundbreaking new technology works, and how you can leverage excluded data to better improve your acquisition campaigns - even those with no interest in Lunio's software will benefit and learn something.</p>",
  },
  {
    title: "I can't make it, is it available on demand?",
    copy: "<p>Yes! If you register above, but can't make it, we will automatically send you a recording of the webinar the next day.</p>",
  },
  {
    title: "I have a question, will there be a Q&A?",
    copy: "<p>Of course! You'll have the opportunity to ask both our product and technical teams any questions you may have.</p>",
  },
  {
    title: "You say EVERY acquisition channel, which will you be discussing?",
    copy: "<p>Lunio's new product suite allows you to protect any ad network or acquisition channel you wish. In this webinar we'll be covering the main networks such as Google, Bing, Facebook, Pinterest, Snap, TikTok, Twitter, etc.</p>",
  },
  {
    title: "I can't see the form/can't sign up!",
    copy: "<p>Some adblockers, or default browser settings on modern builds of browsers like firefox can sometimes get in the way of our forms. If you don't see a form at the top of this page then please disable your adblock, or try an alternative browser.</p>",
  },
]

export default faq
